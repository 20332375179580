import React from "react";
import "./PreHeader.css";
import {
  AiFillFacebook,
  AiFillTwitterCircle,
  AiFillLinkedin,
  AiOutlineInstagram,
} from "react-icons/ai";

function PreHeader() {
  return (
    <div className="pre_header_main">
      <div>
        <a href="tel:8484370444">{"+1 (732) 485-1205"}</a>
        <hr />
        <a href="mailto:info@haraitsolutions.com">
          {"info@haraitsolutions.com"}
        </a>
      </div>
      <div>
        <a
          href="https://www.facebook.com/profile.php?id=100092378850510"
          target="_blank"
        >
          <AiFillFacebook className="social_btn" size={22} color="#fff" />
        </a>
        <a href="http://instagram.com/hara_it_solutions/" target="_blank">
          <AiOutlineInstagram className="social_btn" size={22} color="#fff" />
        </a>
        <a href="https://twitter.com/solutions_hara" target="_blank">
          <AiFillTwitterCircle
            hr
            className="social_btn"
            size={22}
            color="#fff"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/hara-it-solutions-llc/"
          target="_blank"
        >
          <AiFillLinkedin className="social_btn" size={22} color="#fff" />
        </a>
      </div>
    </div>
  );
}

export default PreHeader;
