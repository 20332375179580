import React, { useRef } from "react";
import "./Contact.css";
import { useEffect } from "react";
import emailjs from "@emailjs/browser";

function Contact(props) {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_0k6wuo4",
        "template_5xzp6r8",
        form.current,
        "_KGnubfZwPWZM0hrA"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact_main">
      <div>
        <h1>
          {props.title !== undefined
            ? props.title
            : "CONTACT US TO SPEAK TO AN EXPERT"}
        </h1>
        <p>
          Thank you for choosing us, the best executive recruiting firm for the
          staffing industry. Please fill out this form with any questions,
          comments or suggestions, and one of our executive recruiters will
          contact you.
        </p>
        <h1>Head Office</h1>
        <p>30 Knightsbridge Road, Suite 525, Piscataway, New Jersey 08854</p>
        <h1>Branch Office</h1>
        <p>30 Knightsbridge Road, Suite 525, Piscataway, New Jersey 08854</p>
        <a>Telephone: +1 (732) 485-1205 </a>
        <a>Email : info@haraitsolutions.com</a>
      </div>
      <div className="div_contact">
        <form ref={form} className="div_form" onSubmit={sendEmail}>
          <label>Name</label>
          <input name="user_name" type="text" />
          <label>Email</label>
          <input name="user_email" type="email" />
          <label>Message(Optional)</label>
          <textarea name="message" />
          <button type="submit">Send Message</button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
